import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "queries"
    }}>{`Queries`}</h1>
    <p>{`At Tia we use the query approach to data loading. That means we let a library – `}<a parentName="p" {...{
        "href": "https://github.com/tannerlinsley/react-query"
      }}>{`React Query`}</a>{` – deal with fiddly details so we can focus on `}<em parentName="p">{`our`}</em>{` stuff.`}</p>
    <p>{`Nobody wants to waste time solving solved problems 😉`}</p>
    <h2 {...{
      "id": "why-queries"
    }}>{`Why queries`}</h2>
    <p>{`React Query is modeled after the Apollo Client from GraphQL. For `}<em parentName="p">{`any`}</em>{` type of data fetching.`}</p>
    <p>{`You give it a function that loads data (from REST in our case) and React Query handles:`}</p>
    <ul>
      <li parentName="ul">{`loading & error states`}</li>
      <li parentName="ul">{`caching`}</li>
      <li parentName="ul">{`global request deduping`}</li>
      <li parentName="ul">{`helpful dev tools`}</li>
      <li parentName="ul">{`reloading stale data`}</li>
      <li parentName="ul">{`retrying until success`}</li>
    </ul>
    <p>{`You give each query a name and the library uses it as a cache key.`}</p>
    <p>{`Names can contain variable data, which makes queries reusable. Similar to how `}<a parentName="p" {...{
        "href": "https://blog.appsignal.com/2018/04/03/russian-doll-caching-in-rails.html"
      }}>{`russian doll caching`}</a>{` works in rails 👉 change the cache `}<em parentName="p">{`key`}</em>{` to invalidate. Invalidating data is too hard.`}</p>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`Load your doggos from the API – `}<a parentName="p" {...{
        "href": "https://dog.ceo/dog-api/documentation/breed"
      }}>{`https://dog.ceo/dog-api/documentation/breed`}</a></p>
    <p>{`Write a query with `}<a parentName="p" {...{
        "href": "https://react-query.tanstack.com/reference/useQuery#_top"
      }}><inlineCode parentName="a">{`useQuery`}</inlineCode></a>{`.`}</p>
    <p>{`Wrap your app in a `}<inlineCode parentName="p">{`QueryClientProvider`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// outside the App
const queryClient = new QueryClient()

// inside the App
<QueryClientProvider client={queryClient}>
  {/* rest of your app */}
</QueryClientProvider>
`}</code></pre>
    <p>{`You'll have to render everything inside the provider. I suggest creating a `}<inlineCode parentName="p">{`<Doggos />`}</inlineCode>{` component if you haven't yet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const { data } = useQuery("doggos", async () => fetch)
`}</code></pre>
    <p>{`Render a `}<inlineCode parentName="p">{`<Spinner>`}</inlineCode>{` while your data is loading. You can pass fake doggos as a third argument to `}<inlineCode parentName="p">{`userQuery`}</inlineCode>{` with `}<inlineCode parentName="p">{`{ initialData }`}</inlineCode>{` to avoid the spinner-of-doom-on-load problem.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`You can find my solution at `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train/tree/solution-queries"
      }}>{`https://github.com/AskTia/tia-react-train/tree/solution-queries`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      